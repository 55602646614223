import {HttpHeaders, HttpParamsOptions} from "@angular/common/http";
import { SearchParametersDto } from "../api-dtos/requests/search-parameters.dto";

export class HttpRequestHelper {

  static getHttpHeaders(data?: unknown) : HttpHeaders {
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${this.getLocalStorageToken()}`,
    );

    let options = { headers };

    if (data instanceof FormData) {
      options = {
        headers: headers.delete("Content-Type"),
      };
    }
    return headers
  }

  static getHttpOptions(headers: HttpHeaders, params?: any) : { [k: string] : any } {
    const response: { [k: string] : any } = {}
    response['headers'] = headers;
    if (params) {
      response['params'] = params;
    }
    return response;
  }

  static getLocalStorageToken() {
    return localStorage.getItem("auth-token");
  }

  static addAccessToken(token: string) {
    return localStorage.setItem("auth-token", token);
  }

  static removeAccessToken() {
    return localStorage.removeItem("auth-token");
  }

}
